<template>
  <div class="container">
    <div class="header" :class="isScroll ? 'ScrollClass' : ''">
      <div class="logo">
        <img
          src="https://image.fulllinkai.com/202112/29/16147cb2d75c5c7785845a45bf83776e.png"
          alt=""
        />
      </div>
      <div class="navs">
        <div
          class="nav"
          @click="moveTo(0)"
          :class="chooseItem == 1 ? 'active' : ''"
        >
          首页
        </div>
        <div
          class="nav"
          @click="moveTo(1)"
          :class="chooseItem == 2 ? 'active' : ''"
        >
          关于5商
        </div>
        <div
          class="nav"
          @click="moveTo(2)"
          :class="chooseItem == 3 ? 'active' : ''"
        >
          伯乐领导力
        </div>
        <div
          class="nav"
          @click="moveTo(3)"
          :class="chooseItem == 4 ? 'active' : ''"
        >
          5Q训练
        </div>
        <div
          class="nav"
          @click="moveTo(4)"
          :class="chooseItem == 5 ? 'active' : ''"
        >
          关于良才
        </div>
        <div
          class="nav"
          @click="moveTo(5)"
          :class="chooseItem == 6 ? 'active' : ''"
        >
          联系我们
        </div>
      </div>
    </div>
    <div class="swiper">
      <swiper :imgList="imgList"></swiper>
    </div>
    <!-- 关于5Q-->
    <div class="about">
      <h1>关于5商</h1>
      <div class="aboutParts">
        <div
          class="aboutPart"
          :class="activeIndex == 1 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(1)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">商数的世界观</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              这取决于对整个形式的感知，以及对其各方面的相互联系
              。如果把任何一个方面孤立于其他方面，那么整个形式就已经消失了。
            </div>
            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://image.fulllinkai.com/202112/30/67bf63f1de166f67b320bb96d0297a52.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://image.fulllinkai.com/202112/30/c4ad452202edc911ea95cfc27d4207a5.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 2 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(2)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">原始的形式</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              潜伏在整个创作中的形式/原型，体现在历史上的英雄身上，表现在神话中，并以艺术形式直观地表现出来。在基督的道成肉身和赎罪中得到重述和重构
              随后由复活的耶稣给予基督的身体（升天恩赐）
            </div>
            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://image.fulllinkai.com/202112/30/c818f444ddf06aec086fe2d1310670c5.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://image.fulllinkai.com/202112/30/dda30e0f295e527b13016bfb1f2fde13.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 3 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(3)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">社会的根脉(五重形态)</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              了解原型的性质及其与神学家所说的
              "创造的秩序"的关系。要做到这一点，我们必须研究这个有点无形但重要的想法的根源。
            </div>
            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://image.fulllinkai.com/202112/30/f0fd8400d32698f2431f7a63545d6fbe.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://image.fulllinkai.com/202112/30/16c8eec02de793342ca1fcb8fe59df88.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 4 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(4)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">五重文化根与芽</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              一旦我们在自己的思维中明确了APEST的五个类别，就不难发现APEST在许多原型、英雄、能力、智慧、能力，甚至是人类活动的整个领域中得到了表达和通过。
            </div>

            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://image.fulllinkai.com/202112/30/62fec61c59b2d6e24a9c60c130e7f93a.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://image.fulllinkai.com/202112/30/55790ac1d6e9b1aae401226d3dfc093c.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 5 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(5)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">新型社会改良运动</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              历史上有一些行为者和机构，他们催化决策，沟通和激励，并动员群体采取行动--这显然是传道者原型的表现。反映了牧羊人的原型，历史上同样充斥着致力于创造社区、守卫和保护社会、克服冲突的人和组织。
            </div>

            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa918eab34d.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa918eab34d.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 第二行 -->
      <div class="aboutParts">
        <div
          class="aboutPart"
          :class="activeIndex == 6 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(6)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">社会中五重领导力</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              以弗所书第4章直接谈到了五方面
              的领导力，即装备追随者，建立他们，并帮助整个基督的身体
              达到合一和成熟。
            </div>
            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa92684b511.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa92684b511.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 7 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(7)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">社会的五重功能</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              我们在行动中反映上帝的美好创造的能力可以平等地服务于所有的五重功能，但可能更充分地表现在使徒和传教士的更多创业和创新的本能。
            </div>
            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa92a37f021.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa92a37f021.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 8 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(8)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">标识 社会的功能身体</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              当我们按照我们内在的设计生活时，我们会发现耶稣通过他的子民所做的事工会变得更有意义、更持久、更有影响。这就是按部就班的教会，按设计的事工。
            </div>
            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa92d82c543.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa92d82c543.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 9 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(9)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">社会贸易工具</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              它们处于休眠状态，直到它们再次被激活。这个概念在5Q中是最真实的。APEST是教会核心DNA的一部分，写进了教会的主要脚本，因此也是教会本能代码的一部分。我们不必将这些能
              力 "引进"教会；它们已经在那里，潜伏着。
            </div>

            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa9338e03cf.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa9338e03cf.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
        <div
          class="aboutPart"
          :class="activeIndex == 10 ? 'aboutHoverClass' : ''"
          @mouseenter="activeChange(10)"
        >
          <div class="aboutTitle">
            <div class="circle"></div>
            <div class="title">从这里到那里的总结</div>
          </div>
          <div class="aboutContent">
            <div class="desc_word">
              我们需要记住，无论是个人还是集体，我们最初是由耶稣赋予A
              PEST的，以便我们能够成熟，并以某种方式达到他的丰盛。因此，我们仅仅同意五重奏是好的神学是不够的。
            </div>

            <div class="desc_pic">
              <img
                class="smallPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa936cf11ab.png"
                alt=""
              />
              <img
                class="bigPic"
                src="https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/02/61fa936cf11ab.png"
                alt=""
              />
              <img
                src="https://image.fulllinkai.com/202112/30/82e70d7d9c9caf6754ceae9db6a1a59b.png"
                alt=""
                class="left-corner-icon"
              />
              <div class="left-corner-icon1"></div>
              <div class="left-corner-icon2"></div>
              <div class="left-corner-icon3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- A.P.E.S.T.模块 -->
    <div class="APEST-part">
      <h1>伯乐领导力</h1>
      <div class="symbols">
        <div class="symbol">
          <div class="pic">
            <img
              src="https://image.fulllinkai.com/202112/30/77a4b6860192e194431e46b5889232e7.png"
              alt=""
            />
          </div>
          <div class="present">A.Q.</div>
          <div class="desc">使徒</div>
        </div>
        <div class="symbol">
          <div class="pic">
            <img
              src="https://image.fulllinkai.com/202112/30/8922f15d1b1172af913dbfe27040a7a2.png"
              alt=""
            />
          </div>
          <div class="present">P.Q.</div>
          <div class="desc">预言家</div>
        </div>
        <div class="symbol">
          <div class="pic">
            <img
              src="https://image.fulllinkai.com/202112/30/f38d495ce77fb27b5f7d566eef815e98.png"
              alt=""
            />
          </div>
          <div class="present">E.Q.</div>
          <div class="desc">传道人</div>
        </div>
        <div class="symbol">
          <div class="pic">
            <img
              src="https://image.fulllinkai.com/202112/30/eb70876ca4aeb6412d8047b39f870222.png"
              alt=""
            />
          </div>
          <div class="present">S.Q.</div>
          <div class="desc">牧羊人</div>
        </div>
        <div class="symbol">
          <div class="pic">
            <img
              src="https://image.fulllinkai.com/202112/30/627c4650324de278ec96c9ca5a0364e0.png"
              alt=""
            />
          </div>
          <div class="present">T.Q.</div>
          <div class="desc">老师</div>
        </div>
      </div>
    </div>
    <!-- 5Q训练 -->
    <div class="train">
      <h1>5Q训练</h1>
      <div class="train-parts">
        <div
          class="train-part"
          :id="trainIndex == 1 ? 'hoverTrainClass' : ''"
          @mouseenter="activeTrainChange(1)"
        >
          <div class="train_title">
            <div class="title">5Q介绍研讨会</div>
          </div>
          <div class="train_content">
            <div class="content_word">
              这个研讨会是为希望在家庭、工作场所和社区中住在APEST的普通人设计的。
            </div>
            <div class="content_icon">
              <img
                src="https://image.fulllinkai.com/202112/30/b9a0a059b284f0469c8fcf6010f9790f.png"
                alt=""
                class="smallIcon"
              />
              <img
                src="https://image.fulllinkai.com/202112/30/ab04cce52423cab2d68094789d41bf7b.png"
                alt=""
                class="bigIcon"
              />
            </div>
          </div>
        </div>
        <div
          class="train-part"
          :id="trainIndex == 2 ? 'hoverTrainClass' : ''"
          @mouseenter="activeTrainChange(2)"
        >
          <div class="train_title">
            <div class="title">5Q领导力加速器</div>
          </div>
          <div class="train_content">
            <div class="content_word">
              5Q领导力加速器适用于任何希望以更快的速度深入了解APEST的领导者。
            </div>
            <div class="content_icon">
              <img
                src="https://image.fulllinkai.com/202112/30/a39685281fea554aec66291622d1be8c.png"
                alt=""
                class="smallIcon"
              />
              <img
                src="https://image.fulllinkai.com/202112/30/8846ddac1aa76a1a00a2c44ebea6822c.png"
                alt=""
                class="bigIcon"
              />
            </div>
          </div>
        </div>
        <div
          class="train-part"
          :id="trainIndex == 3 ? 'hoverTrainClass' : ''"
          @mouseenter="activeTrainChange(3)"
        >
          <div class="train_title">
            <div class="title">5Q训练教练</div>
          </div>
          <div class="train_content">
            <div class="content_word">
              这个研讨会是为希望在家庭、工作场所和社区中住在APEST的普通人设计的。
            </div>
            <div class="content_icon">
              <img
                src="https://image.fulllinkai.com/202112/30/ad36e704f68f07b1de35a93cb2e46a02.png"
                alt=""
                class="smallIcon"
              />
              <img
                src="https://image.fulllinkai.com/202112/30/88183f9c26b8b1478f8dab13ca37e3c9.png"
                alt=""
                class="bigIcon"
              />
            </div>
          </div>
        </div>
        <div
          class="train-part"
          :id="trainIndex == 4 ? 'hoverTrainClass' : ''"
          @mouseenter="activeTrainChange(4)"
        >
          <div class="train_title">
            <div class="title">5Q学习社区</div>
          </div>
          <div class="train_content">
            <div class="content_word">
              这个研讨会是为希望在家庭、工作场所和社区中住在APEST的普通人设计的。
            </div>
            <div class="content_icon">
              <img
                src="https://image.fulllinkai.com/202112/30/65a7bd0b2e17a7b0bd48b0610d1a172f.png"
                alt=""
                class="smallIcon"
              />
              <img
                src="https://image.fulllinkai.com/202112/30/970c4f5b5e71017f49c9a74dba2830d4.png"
                alt=""
                class="bigIcon"
              />
            </div>
          </div>
        </div>
        <div
          class="train-part"
          :id="trainIndex == 5 ? 'hoverTrainClass' : ''"
          @mouseenter="activeTrainChange(5)"
        >
          <div class="train_title">
            <div class="title">5Q辅导小组</div>
          </div>
          <div class="train_content">
            <div class="content_word">
              这个研讨会是为希望在家庭、工作场所和社区中住在APEST的普通人设计的。
            </div>
            <div class="content_icon">
              <img
                src="https://image.fulllinkai.com/202112/30/334a572d00824d93162aaa3fc257e55e.png"
                alt=""
                class="smallIcon"
              />
              <img
                src="https://image.fulllinkai.com/202112/30/5c09660f8fb4311c0347a88e2729c888.png"
                alt=""
                class="bigIcon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer底部 -->
    <div class="footer">
      <div class="pic">
        <img
          src="https://image.fulllinkai.com/202112/30/0390828a7687e177a5d78a5983b83437.png"
          alt=""
        />
      </div>
      <div class="des">
        <div class="about_5Q">关于5Q</div>
        <div class="">联系我们</div>
      </div>
    </div>
  </div>
</template>
<script>
import swiper from "../components/swiper.vue";
export default {
  components: {
    swiper,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      getScroll: 0,
      imgList: [
        {
          id: 1,
          img: "https://image.fulllinkai.com/202112/29/b01bad4833554a38a5073d975c73ed76.png",
        },
        {
          id: 2,
          img: "https://image.fulllinkai.com/202112/29/b01bad4833554a38a5073d975c73ed76.png",
        },
      ],
      //   滑动后给header 添加样式
      isScroll: false,
      logoSrc:
        "https://image.fulllinkai.com/202112/29/16147cb2d75c5c7785845a45bf83776e.png",
      activeIndex: 1,
      chooseItem: 1,
      trainIndex: 1,
    };
  },
  created() {},
  methods: {
    activeChange(index) {
      this.activeIndex = index;
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0) {
        this.isScroll = true;
        //滚动大于0的时候要做的操作
      }
      if (scrollTop == 0) {
        this.isScroll = false;
      }
    },
    moveTo(num) {
      this.chooseItem = num + 1;
      //   点击首页
      if (num == 0) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      //   点击 '关于5商'
      if (num == 1) {
        let target = document.querySelector(".about");
        // 修正数值
        let adjust = target.offsetTop - 100;
        window.scrollTo({
          top: adjust,
          behavior: "smooth",
        });
      }
      //  APEST
      if (num == 2) {
        let target = document.querySelector(".APEST-part");

        let adjust = target.offsetTop - 100;
        window.scrollTo({
          top: adjust,
          behavior: "smooth",
        });
      }
      // 5Q 训练
      if (num == 3) {
        let target = document.querySelector(".train");

        let adjust = target.offsetTop - 100;
        window.scrollTo({
          top: adjust,
          behavior: "smooth",
        });
      }
      if (num == 4) {
        this.$router.push({
          path: "/welcome",
        });
      }
      if (num == 5) {
        let target = document.querySelector(".footer");
        let adjust = target.offsetTop - 100;
        window.scrollTo({
          top: adjust,
          behavior: "smooth",
        });
      }

      //   let nav = navList[num];
      //   console.log(nav);
    },
    activeTrainChange(index) {
      this.trainIndex = index;
    },
  },
};
</script>
<style  lang='less' scoped>
.container {
  overflow-x: hidden;
  .header {
    position: fixed;
    display: flex;
    height: 100px;
    width: 100%;
    z-index: 10;
    .logo {
      margin: 20px 0 0 150px;
      flex-grow: 1;
      text-align: left;
      img {
        width: 50px;
        height: 50px;
        object-fit: fill;
      }
    }
    .navs {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      color: #fff;

      .nav {
        font-size: 20px;
        margin-right: 40px;
        cursor: pointer;
      }
      .nav:hover {
        color: #0bb3d2;
      }
    }
  }
  .about {
    h1 {
      font-size: 40px;
      color: #000;
      margin: 50px 0 100px 0;
    }
    .aboutParts {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 500px;
      .aboutPart {
        // flex: 1;
        width: 230px;
        height: 380px;
        color: #000;
        border-radius: 16px 16px 16px 16px;
        border: 1px solid #91dcee;
        .aboutTitle {
          display: flex;
          align-items: center;
          height: 80px;
          border-radius: 16px 16px 0 0;
          background: #91dcee;
          .circle {
            display: inline-block;
            height: 12px;
            width: 12px;
            padding: 3px;
            margin: 0 10px 0 40px;
            background-color: #91dcee;
            border-radius: 50%;
            border: 3px solid #34a8c5;
          }
          .title {
            position: relative;
            font-size: 16px;
            font-weight: 550;
            z-index: 2;
          }
          .title::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 8px;
            background: #08b5c4;
            border-radius: 14px;
            opacity: 0.6;
            bottom: 0;
            left: 50%;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            z-index: -1;
          }
        }
        .aboutContent {
          padding: 40px 0 0 20px;
        }
        .desc_word {
          color: #333;
          display: inline-block;
          width: 180px;
          overflow: hidden;
          // text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 16px;
        }
        .left-corner-icon {
          display: none;
        }
        .desc_pic {
          margin-top: 60px;
          .bigPic {
            display: none;
          }
        }
      }
      .aboutHoverClass {
        box-shadow: 0 5px 22px 0 #ccf4ff;
        position: relative;
        width: 440px !important;

        .aboutTitle {
          position: relative;
          color: #fff !important;
          background-color: #34a8c5;
          .circle {
            background-color: #34a8c5;
            border: 3px solid #fff;
          }
          .title::before {
            background-color: #0f828f;
          }
          .title {
            font-size: 26px !important;
          }
        }
        .desc_word {
          width: 260px;
          text-align: left;
          overflow: initial;
          -webkit-line-clamp: initial;
        }
        .desc_pic {
          .smallPic {
            display: none;
          }
          .bigPic {
            display: block;
            position: absolute;
            bottom: 5px;
            right: 34px;
            width: 160px;
            height: 160px;
            z-index: 1;
          }
        }
        .aboutTitle::after {
          content: "";
          width: 98px;
          height: 116px;
          background: url(https://image.fulllinkai.com/202112/30/d0ac6567f3b38273f997dcfdf5da7ef1.png)
            50% / cover;
          position: absolute;
          right: 0;
          top: 0;
        }
        .left-corner-icon {
          display: block !important;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 98px;
          height: 116px;
        }
        .left-corner-icon2 {
          position: absolute;
          left: 100px;
          bottom: 100px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: linear-gradient(232deg, #34a8c5, #34a8c5);
          opacity: 0.09;
        }
        .left-corner-icon1 {
          position: absolute;
          left: 20px;
          bottom: 140px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: linear-gradient(232deg, #34a8c5, #34a8c5);
          opacity: 0.07;
        }
        .left-corner-icon3 {
          position: absolute;
          left: 170px;
          bottom: 30px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: linear-gradient(232deg, #34a8c5, #34a8c5);
          opacity: 0.12;
        }
      }
    }
  }
  .APEST-part {
    height: 400px;
    h1 {
      font-size: 40px;

      color: #000;
    }
    .symbols {
      margin-top: 70px;
      display: flex;
      justify-content: center;
    }
    .symbol {
      display: flex;
      margin: 0 100px;
      flex-direction: column;
    }
  }
  .train {
    h1 {
      font-size: 40px;
      color: #000;
    }
  }
  //   滑动后添加的样式
  .ScrollClass {
    border-bottom: 0.026667rem solid #f5f5f5;
    background-color: #fff;
    .nav {
      color: #333;
    }
  }
  .train {
    height: 800px;
    h1 {
      margin-bottom: 100px;
    }
    .train-parts {
      display: flex;
      justify-content: center;
      width: 100vw;
      .train-part {
        border: 1px solid #59c5df;
        position: relative;
        border-radius: 16px;
        height: 430px;
        width: 200px;
        .bigIcon {
          display: none;
        }
      }
      .train_title {
        display: flex;
        position: relative;
        margin-top: 80px;
        font-size: 20px;
        font-weight: 550;
        color: #000;
        justify-content: center;
        .title {
          position: relative;
          font-size: 22px;
          font-weight: 550;
          z-index: 2;
        }
      }
      .title::before {
        content: "";
        display: block;
        width: 100%;
        height: 8px;
        background: #08b5c4;
        border-radius: 14px;
        opacity: 0.6;
        position: absolute;
        bottom: -0.053333rem;
        left: 50%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
        z-index: -1;
      }
      .content_word {
        padding: 40px 20px;
      }
    }
    // id 选择器 增加权重
    #hoverTrainClass {
      box-sizing: border-box;
      width: 400px;
      border-top-left-radius: 30px;
      .smallIcon {
        display: none;
      }
      .bigIcon {
        margin: 40px auto 0 auto;
        display: block;
      }
      .train_title {
        margin-top: 0;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        border-top-right-radius: 16px;
        background: url("https://image.fulllinkai.com/202112/30/118b542fbb19c93d81f22b61f59ca340.png");
        width: 400px;
        height: 60px;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 16px 16px 0 0;
        top: -1px;
        left: -1px;
      }
      .title {
        color: #fff;
      }
    }
  }
  .footer {
    background-color: #272b2e;
    height: 200px;
    padding-top: 80px;
    img {
      width: 85px;
      height: 90px;
      display: block;
      margin: 0 auto 1.066667rem;
    }
    .des {
      margin-top: 40px;
      justify-content: center;
      display: flex;
      font-size: 20px;
      color: #666;
      .about_5Q {
        margin-right: 50px;
      }
    }
  }
  //   标题栏选中后添加的样式
  .active {
    color: #0bb3d2;
  }
}
@media screen and (max-width: 1300px) {
}
</style>